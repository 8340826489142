<template>
  <div>
    <iframe
      :key="true"
      src="https://www.zjgcpt.cn/gps-web/h5/outside?userId=jcbs01&password=5bf8a3a69f093aab40d8c328a9d958a2"
      frameborder="0"
      scrolling="no"
      width="100%"
      style="min-height: 82vh; vertical-align: top"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "page-visual",
  data() {
    return {
      pageData: null,
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.cell {
  padding: 10px 0;
  //   background-color: #fff;
  display: flex;
  justify-content: space-between;
}
.cell:nth-child(n + 2) {
  margin-top: 20px;
}
</style>